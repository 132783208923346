/* eslint-disable */
import $ from "jquery";
import Alert from "../../js/src/alert";

const alertClass = "alert";

const flashAlertsContainer = document.querySelectorAll(
  ".flash-alerts-container"
);

const setupContainer = (container) => {
  dismissFlashAlerts(container);
  setupObserver(container);
};

const setupObserver = (container) => {
  const observer = new MutationObserver((mutations) => {
    mutations.filter(hasAddedNodes).forEach((mutation) => {
      Array.from(mutation.addedNodes)
        .filter(isElementNode)
        .filter(isAlertElement)
        .forEach(dismissAfterTimeout);
    });
  });

  observer.observe(container, {
    childList: true,
  });
};

const hasAddedNodes = (mutation) => mutation.addedNodes.length > 0;
const isElementNode = (node) => node.nodeType === Node.ELEMENT_NODE;
const isAlertElement = (element) => element.classList.contains(alertClass);

const dismissAfterTimeout = (alertElement) => {
  const dismissDelay = alertElement.getAttribute("data-dismiss-delay") * 1000;
  forwardDismissDelayToCSS(alertElement, dismissDelay);

  setTimeout(() => {
    $(alertElement).alert("close");
  }, dismissDelay);
};

const forwardDismissDelayToCSS = (alertElement, duration) =>
  alertElement.style.setProperty("--dismiss-delay", `${duration}ms`);

const dismissFlashAlerts = (container) =>
  fetchFlashAlerts(container).forEach(dismissAfterTimeout);

const fetchFlashAlerts = (container) =>
  container.querySelectorAll(`.${alertClass}`);

flashAlertsContainer.forEach(setupContainer);

export { Alert };
